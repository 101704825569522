@import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '@fortawesome/fontawesome-free/scss/solid.scss';
@import '@fortawesome/fontawesome-free/scss/regular.scss';
@import '@fortawesome/fontawesome-free/scss/brands.scss';

// // @import 'mdb-angular-ui-kit/assets/scss/mdb.scss';

// $my-theme-primary: #5CD000; // theme primary color, change this value to customize theme
// $my-theme-secondary: #504465; // theme secondary color, change this value to customize theme

// $my-theme: mdb-light-theme($my-theme-primary, $my-theme-secondary); // create the new theme using primary and secondary colors

// // include theme styles
// @include mdb-theme($my-theme);


.background-verde{
    background-color: #5CD000;
    color: white;
}
.background-gris{
    background-color: #aaaaaa;
}

h3, .h3 {
    font-size: 3.25rem;
}

.navbar-expand-lg .navbar-collapse {
    justify-content: flex-end;
}

.bg-light {
    background-color: #ffffff !important;
}
h6, .h6 {
    font-size: 1.35rem;
}
h7, .h7 {
    font-size: 1.65rem;
}

   